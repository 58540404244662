<script setup lang="ts">
import type { FormInstance, FormRules } from 'element-plus'

const props = defineProps<{
  isDisabled?: boolean
  textarea?: any
}>()
const emits = defineEmits(['update:textarea'])

const formRef = ref<FormInstance>()

const rules = ref<FormRules>({
  textarea: [
    { required: true, message: '请填入要查询的卡片信息', trigger: 'blur' }
  ]
})

const count = computed(() => {
  if (form.value.textarea && form.value.textarea.trim() === '') {
    return 0
  } else {
    return (
      form.value.textarea &&
      form.value.textarea.split('\n').filter((item: any) => item.trim() !== '')
        .length
    )
  }
})

const form = computed(() => {
  if (props.isDisabled) {
    const arr =
      props.textarea &&
      props.textarea
        .map((i) => {
          return `${i.card_id}|${i.card_off_month}|${i.card_off_year}|${i.card_cvv}|${i.card_details}\n`
        })
        .join('')

    return { textarea: arr }
  } else {
    return { textarea: props.textarea }
  }
})
// const form = ref({
//   textarea: props.textarea
// })

const handleInput = (value: any) => {
  emits('update:textarea', form.value.textarea)
}

defineExpose({ formRef })
</script>

<template>
  <el-form ref="formRef" :rules="isDisabled ? undefined : rules" :model="form">
    <el-form-item prop="textarea">
      <el-input
        v-model="form.textarea"
        @input="handleInput"
        :rows="10"
        type="textarea"
        :placeholder="
          isDisabled ? '' : '请输入要查询的卡号，多个卡号通过回车(Enter)换行'
        "
        :readonly="isDisabled"
        resize="none"
      >
      </el-input>
    </el-form-item>
  </el-form>
  <span v-if="!isDisabled" class="count">{{ count }}</span>
</template>

<style lang="scss" scoped>
.el-form {
  width: 100%;
  height: 100%;
  .el-form-item {
    margin-bottom: unset;
  }
}
.el-textarea {
  :deep(.el-textarea__inner) {
    text-wrap: nowrap;
  }
}
.count {
  position: absolute;
  right: 14px;
  bottom: 2px;
}
</style>
