<script setup lang="ts">
import {
  getBalanceAPI,
  logoutAPI,
  messageAPI,
  setMessageStatusAPI
} from '@/api/user'
import { useUserStore } from '@/stores'
import { ElMessage, ElNotification } from 'element-plus'
import { onMounted } from 'vue'
import { RouterView, useRouter } from 'vue-router'
// 提示引入
import { getCurrentInstance } from 'vue'


import image from '@/assets/images/image.png'
import ingif from '@/assets/images/ingif.gif'
const router = useRouter()
const store = useUserStore()
const handleSelect = () => { }
//const timer = ref<ReturnType<typeof setInterval> | null>(null) // 使用 ref 存储定时器 ID

const getBalance = async () => {
  if (!store.userId) return
  try {
    const res = await getBalanceAPI({ user_id: store.userId })
    // console.log(res.data.balance)
    store.setBalance(res.data.balance)
  } catch (error: any) {
    ElMessage.info(error)
  }
}

let intervalId = null;

onMounted(() => {
  if (store.userId === '')
    return
  getBalance()
  //不是管理员不执行
  if (store.isSuperUser === '0') return
  //五分钟轮询一次
  getTableData()
  intervalId = setInterval(getTableData, 300000); // 每 5 秒调用一次


})
onBeforeUnmount(() => { clearInterval(intervalId); });

const logout = async () => {

  // if (timer.value) {
  // clearInterval(timer.value) // 清除定时器
  //timer.value = null // 重置定时器 ID
  // }
  store.removeUser()
  try {
    await logoutAPI()
    store.removeUser()
    ElMessage.success('已退出')
    router.push('/login')
  } catch (error: any) {
    ElMessage.info(error)
  }
}

const handleCommand = (command: string) => {
  router.push(command)
}



const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));








// 消息提示/
const curPage = ref(1)

const getTableData = async () => {
  const params = {
    page: curPage.value,
    per_page: 5,
    is_read: "0"
  }

  console.log("请求次数", curPage.value);

  // curPage.value = curPage.value + 1
  try {
    const res = await messageAPI(params)

    let arr = res.data.sys_message_que_res.filter((value: any, index: any) => {
      return value.is_read == 0
    })
    //console.log(arr);


    for (let i = 0; i < arr.length; i++) {
      //console.log(arr[i]);
      open2(arr[i])
      await delay(1000)
    }

  } catch (error) {
    // ElMessage.info(error)
  }
}
// 1、 //不是管理员不显示
// 2、 五分钟调一下查询信息接口，只展示未读得，已读得过滤掉，
//3，最多显示5条，超过5条不显示 一条一条得显示，暂时设置3000


//4、 点击关闭， 调接口setMessageStatusAPI  ，传参message_id  is_read 状态 1已读  0未读 返回的话，就返回成功或者失败，200或者500



const open2 = (data: any) => {
  console.log(data);

  ElNotification({
    zIndex: 9999,
    message: data.message,
    position: 'bottom-right',
    onClose: (() => {
      closeNotification(data)
    }),
    duration: 0

  })
}




//关闭单个通知
const closeNotification = async (e: any) => {
  console.log(e)
  // 关闭之后传参   用户message_id   is_read 状态 1已读  0未读
  const params = {
    message_id: e.message_id,
    is_read: "1"
  }
  const res = await setMessageStatusAPI(params)
  console.log(res);
  if (res.status == 200) {
    ElMessage({
      message: '已读',
      type: 'success',
    })
  } else {
    return false
  }
}
</script>

<template>
  <div class="head">
    <div class="left">
      <img :src="image" alt="" />
    </div>
    <div class="conter">
      <el-menu active-text-color="#000" text-color="ccc" :default-active="$route.path" class="nav" mode="horizontal"
        router @select="handleSelect" :ellipsis="false">
        <!-- 用户 -->
        <el-menu-item index="/home">验卡</el-menu-item>
        <!-- <el-menu-item index="/about">关于我们</el-menu-item> -->
        <el-menu-item index="/admin-bin">查询BIN</el-menu-item>
        <el-menu-item index="/order">查询订单</el-menu-item>
        <el-menu-item index="/docs">文档</el-menu-item>
        <!-- 管理员 -->
        <!--  -->
        <el-sub-menu index="" v-if="store.isSuperUser === '1'">
          <template #title>
            <span>管理员</span>
          </template>
          <el-menu-item index="/admin-key">管理KEY</el-menu-item>
          <el-menu-item index="/admin-order">管理员订单</el-menu-item>
          <el-menu-item index="/admin-setting">接口轮询配置</el-menu-item>
          <el-menu-item index="/admin-message">消息通知</el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
    <div class="right">
      <el-text v-if="store.userId">余额:{{ store.balance }}</el-text>
      <el-text v-else></el-text>
      <el-dropdown placement="top-end">
        <img :src="ingif" alt="" />
        <template #dropdown>
          <el-dropdown-menu v-if="store.userId">
            <el-dropdown-item @click="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
  <keep-alive>
    <RouterView />

  </keep-alive>
</template>

<style lang="scss" scoped>
.head {
  margin: 0 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  color: #ccc !important;

  .left {
    img {
      width: 190px;
      height: 80px;
    }
  }

  .conter {
    width: 65%;
    padding: 20px;
    color: #ccc !important;

    el-menu-item.is-active {
      background-color: #ffffff !important;

      span {
        color: #000 !important;
      }
    }

    .el-menu-item.is-active {
      color: #000 !important;
      background-color: #ffffff !important;
    }

    .nav {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      color: #ccc;
      font-size: 18px !important;
      border: none !important;
    }
  }

  .right {
    width: 200px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}

.el-dropdown {
  border: 0;
}
</style>
