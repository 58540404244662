import 'element-plus/theme-chalk/el-message-box.css'
import 'element-plus/es/components/message/style/css'
import 'v-calendar/style.css'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import VCalendar from 'v-calendar'
import 'nprogress/nprogress.css'
import '@/styles/main.scss'
import "element-plus/es/components/notification/style/css" 
import "element-plus/theme-chalk/el-notification.css"
import { createApp } from 'vue'
import pinia from './stores'

import App from './App.vue'
import router from './router'

// eslint-disable-next-line prettier/prettier
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
app.use(ElementPlus, {
  locale: zhCn
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(pinia)
app.use(router)
app.use(VCalendar, { componentPrefix: 'vc' })
app.mount('#app')
