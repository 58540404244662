import axios, {
  type AxiosResponse,
  type InternalAxiosRequestConfig
} from 'axios'

const baseURL = import.meta.env.VITE_APP_API_URL
// const baseURL = ''
const request = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
})

request.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (
      ['post', 'put', 'patch'].includes(config.method as string) &&
      config.data !== null
    ) {
      config.data = JSON.stringify(config.data)
    }
    return config
  },
  (err) => Promise.reject(err)
)

request.interceptors.response.use(
  (response: AxiosResponse) => {
    // 检查配置的响应类型是否为二进制类型（'blob' 或 'arraybuffer'）, 如果是，直接返回响应对象
    if (
      response.config.responseType === 'blob' ||
      response.config.responseType === 'arraybuffer'
    ) {
      return response
    }

    return response
  },
  (err: any) => {
    return Promise.reject(err.message)
  }
)

// type Data<T> = {
//   code: number
//   message: string
//   data: T
// }
// // 4. 请求工具函数
// const request = <T>(
//   url: string,
//   method: Method = 'get',
//   submitData?: object
// ) => {
//   return instance.request<T, Data<T>>({
//     url,
//     method,
//     [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData
//   })
// }

export { baseURL, request }
