import { request, baseURL } from '@/utils/request'

export const loginAPI = (data: any) =>
  request({ url: `${baseURL}/login`, data, method: 'post' })

export const logoutAPI = () =>
  request({ url: `${baseURL}/logout`, method: 'post' })

export const createUserAPI = (data: any) =>
  request({ url: `${baseURL}/create_user`, data, method: 'post' })

export const getBalanceAPI = (data: any) =>
  request({ url: `${baseURL}/get_balance`, data, method: 'post' })

// 验卡
export const cardCheckAPI = (data: any) =>
  request({ url: `${baseURL}/card_check`, data, method: 'post' })

// 验卡进度
export const checkTimesAPI = (data: any) =>
  request({ url: `${baseURL}/check_times`, data, method: 'post' })

// 验卡页面下拉
// export const selInterfaceForCheckAPI = () => {
//   request({ url: `${baseURL}/sel_interface_for_check`, method: 'post' })
// }

// 轮询接口配置
export const selInterfaceAPI = (data: any) =>
  request({ url: `${baseURL}/sel_interface`, method: 'POST', data })
// 提交
export const setInterfaceAPI = (data: any) =>
  request({ url: `${baseURL}/set_interface`, method: 'post', data })

export const selAllInterfaceAPI = (data: any) =>
  request({ url: `${baseURL}/sel_all_interface`, method: 'post', data })

export const setApiInterfaceAPI = (data: any) =>
  request({ url: `${baseURL}/set_api_interface`, method: 'post', data })
// 消息通知 params
export const messageAPI = (data: any) =>
  request({ url: `${baseURL}/sel_message`, method: 'post',data })


// 设置状态
export const setMessageStatusAPI = (data: any) =>
  request({ url: `${baseURL}/set_message_status`, method: 'post',data })




