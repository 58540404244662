<script setup lang="ts">
import { cardCheckAPI, checkTimesAPI, selInterfaceAPI, getBalanceAPI } from '@/api/user'
import type CoInputTextarea from '@/components/CoInputTextarea.vue'
import { useUserStore } from '@/stores'


const store = useUserStore()
const router = useRouter()

const searchRef = ref<InstanceType<typeof CoInputTextarea>>()
const selectVal = ref('')

const options = ref([])
const textarea = ref('')
const isSearch = ref(false)
const subTextarea = ref({
  done: 0,
  total: 0,
  live_cards_list: [] as any,
  dead_cards_list: [] as any,
  unknown_cards_list: [] as any,
  format_error_cards_list: [] as any
})

const percentage = computed(() => {
  return (
    Math.trunc((subTextarea.value.done / subTextarea.value.total) * 100) || 0
  )
})




const searchBtn = ref(true)
const card_check_no = ref("")
const search = async () => {
  if (store.userId === '') return router.push('/login')

  subTextarea.value = {
    done: 0,
    total: 0,
    live_cards_list: [],
    dead_cards_list: [],
    unknown_cards_list: [],
    format_error_cards_list: []
  }
  let check_list: any = []
  textarea.value.split('\n').forEach((i) => {
    let card_arr = i.split(/[|/]/)
    // 只取前四个元素
    const [
      card_id,
      card_off_month,
      card_off_year,
      card_cvv,
      card_country,
      card_full_name,
      card_street,
      card_city,
      card_state,
      card_postal,
      card_phone
    ] = card_arr
    // 将第三个字段及其后的字段拼接成一个字段
    const additionalFields = card_arr.slice(4).join('|') // 使用 '|' 连接
    const card_details = `${additionalFields}` // 组合成新的字段
    check_list.push({
      card_id,
      card_off_year,
      card_off_month,
      card_cvv,
      card_country,
      card_full_name,
      card_street,
      card_city,
      card_state,
      card_postal,
      card_phone,
      user: store.userId,
      status: '',
      card_details
    })
  })

  check_list = check_list.filter((i: any) => i.card_id !== '')

  if (store.balance < check_list.length)
    return ElMessage.error(`目前余额只能验${store.balance}张卡`)

  const searchData = {
    user_id: store.userId,
    balance: store.balance,
    check_list,
    interfaces: selectVal.value || []
  }

  searchRef.value!.formRef?.validate(async (valid) => {
    if (valid) {
      searchBtn.value = false

      try {
        const res = await cardCheckAPI(searchData)
        card_check_no.value = res.data.card_check_no

        store.setCardCheckNo(res.data.card_check_no)


        isSearch.value = true
        const timerId = setInterval(async () => {
          try {
            const {
              data: { check_list_done: res }
            } = await checkTimesAPI({
              user_id: store.userId,
              card_check_no: card_check_no.value
            })
            subTextarea.value.done = res.done
            subTextarea.value.total = res.total
            subTextarea.value.live_cards_list.push(
              ...res.check_list_done.live_cards_list
            )
            subTextarea.value.dead_cards_list.push(
              ...res.check_list_done.dead_cards_list
            )
            subTextarea.value.unknown_cards_list.push(
              ...res.check_list_done.unknown_cards_list
            )
            subTextarea.value.format_error_cards_list.push(
              ...res.check_list_done.format_error_cards_list
            )

            if (res.done === res.total) {
              clearInterval(timerId)
              searchBtn.value = subTextarea.value.done === subTextarea.value.total

              getBalance()
            }

            // if (subTextarea.done.value===subTextarea.total.value)
          } catch (error: any) {
            // ElMessage.info(error)
          }
        }, 1000)



        // searchBtn.value = true
      } catch (error: any) {
        ElMessage(error)
      }
    } else {
      // console.log(valid)
    }
  })
}


const getBalance = async () => {
  if (!store.userId) return
  try {
    const res = await getBalanceAPI({ user_id: store.userId })
    // console.log(res.data.balance)
    store.setBalance(res.data.balance)
  } catch (error: any) {
    ElMessage.info(error)
  }
}




// 是否又可用接口
const isUse = ref(false)
const selInterface = async () => {
  if (!store.userId) return

  try {
    const res = await selInterfaceAPI({ page: 1, per_page: 10 })
    options.value = res.data.interface_que_res.map((i: any) => ({
      label: i.interface,
      value: i.interface,
      disable: i.interface_status === '0' ? false : true,
      sx_date: i.sx_date
    }))

    isUse.value = res.data.interface_que_res.every(
      (i) => i.interface_status === '1'
    )
    if (res.data.interface_que_res.every((i) => i.interface_status === '1'))
      ElMessage.info('暂无接口')
    // }
  } catch (e: any) {
    ElMessage.info(e)
  }
}

onMounted(() => {
  selInterface()

})

const copy_export_data = (data) => {
  const safeValue = (value) => (value === null ? '' : value)
  const str = data
    .map((i) => {
      console.log(data)
      // 只取前四个字段
      const card_id = safeValue(i.card_id)
      const card_off_month = safeValue(i.card_off_month)
      const card_off_year = safeValue(i.card_off_year)
      const card_cvv = safeValue(i.card_cvv)
      const card_details = safeValue(i.card_details)
      // 拼接其余字段作为 card_detail
      // const card_detail = `${safeValue(i.card_country)}|${safeValue(i.card_full_name)}|${safeValue(i.card_street)}|${safeValue(i.card_city)}|${safeValue(i.card_state)}|${safeValue(i.card_postal)}|${safeValue(i.card_phone)}`
      return `${card_id}|${card_off_month}|${card_off_year}|${card_cvv}|${card_details}\n`
    })
    .join('')
  return str
}

// 复制
const copyTextToClipboard = async (text: any) => {
  try {
    await navigator.clipboard.writeText(copy_export_data(text))
    ElMessage.success('复制成功')
  } catch (error: any) {
    // ElMessage(error)
  }
}

// 导出
const exportToTxt = (text: any, filename: string) => {
  console.log(typeof text)
  let blob
  if (filename === '验卡') {
    blob = new Blob([text], { type: 'text/plain' })
  } else {
    blob = new Blob([copy_export_data(text)], { type: 'text/plain' })
  }
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = filename || 'data.txt'
  document.body.appendChild(link) // 需要添加到DOM中才能触发点击
  link.click()
  URL.revokeObjectURL(link.href) // 释放 URL 对象
  document.body.removeChild(link)
}
</script>

<template>
  <div class="check">
    <div class="head">
      <h4>卡片查询</h4>
      <div class="head_right">
        <!-- <el-select
          v-model="selectVal"
          placeholder="选择接口"
          size="large"
          clearable
          placement="bottom"
          style="width: 240px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select> -->
        <el-button @click="exportToTxt(textarea, '验卡')">导出</el-button>
      </div>
    </div>
    <div class="search">
      <co-input-textarea ref="searchRef" v-model:textarea="textarea"></co-input-textarea>
    </div>
    <el-button v-if="searchBtn" @click="search" :disabled="isUse">查询</el-button>
    <el-button v-else type="primary">查询中...</el-button>
  </div>

  <div class="check_result">
    <div v-if="isSearch" class="progress">
      <ul class="progress_info">
        <li>检索({{ subTextarea.done }})&nbsp;&nbsp;</li>
        <li>
          活卡({{ subTextarea.live_cards_list?.length || 0 }})&nbsp;&nbsp;
        </li>
        <li>
          死卡({{ subTextarea.dead_cards_list?.length || 0 }})&nbsp;&nbsp;
        </li>
        <li>
          错误卡({{ subTextarea.unknown_cards_list?.length || 0 }})&nbsp;&nbsp;
        </li>
        <li>
          格式错误卡({{
            subTextarea.format_error_cards_list?.length || 0
          }})&nbsp;&nbsp;
        </li>
      </ul>
      <el-progress :percentage="percentage" :stroke-width="15" striped
        :striped-flow="percentage === 100 ? false : true" />
    </div>
    <div class="result_top">
      <h4>
        <span>活卡</span>
        <div>
          <el-button @click="copyTextToClipboard(subTextarea.live_cards_list)">复制</el-button><el-button
            @click="exportToTxt(subTextarea.live_cards_list, '活卡')">导出</el-button>
        </div>
      </h4>
      <co-input-textarea ref="test" v-model:textarea="subTextarea.live_cards_list"
        :is-disabled="true"></co-input-textarea>
    </div>
    <ul class="result_bottom">
      <li>
        <h4>
          <span>死卡</span>
          <div>
            <el-button @click="copyTextToClipboard(subTextarea.dead_cards_list)">复制</el-button><el-button
              @click="exportToTxt(subTextarea.dead_cards_list, '死卡')">导出</el-button>
          </div>
        </h4>
        <co-input-textarea ref="test1" v-model:textarea="subTextarea.dead_cards_list"
          :is-disabled="true"></co-input-textarea>
      </li>
      <li>
        <h4>
          <span>未知错误卡</span>
          <div>
            <el-button @click="copyTextToClipboard(subTextarea.unknown_cards_list)">复制</el-button><el-button
              @click="exportToTxt(subTextarea.unknown_cards_list, '错误卡')">导出</el-button>
          </div>
        </h4>
        <co-input-textarea ref="test2" v-model:textarea="subTextarea.unknown_cards_list"
          :is-disabled="true"></co-input-textarea>
      </li>
      <li>
        <h4>
          <span>格式错误卡</span>
          <div>
            <el-button @click="copyTextToClipboard(subTextarea.format_error_cards_list)">复制</el-button><el-button
              @click="
                exportToTxt(subTextarea.format_error_cards_list, '格式错误卡')
                ">导出</el-button>
          </div>
        </h4>
        <co-input-textarea ref="test3" v-model:textarea="subTextarea.format_error_cards_list"
          :is-disabled="true"></co-input-textarea>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.check {
  margin: auto;
  width: 94%;
  background-color: var(--main);
  padding: 0 20px 10px;
  border-radius: 10px;

  li {
    line-height: normal;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    :deep(.head_right) {
      display: flex;
      align-items: center;

      .el-select--large .el-select__wrapper {
        min-height: 40px;
      }

      .el-button {
        height: 30px;
        padding: 0 15px;
      }
    }

    .el-select {
      margin-right: 20px;
    }
  }

  .search {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  >.el-button {
    display: block;
    margin: 12px auto 0;
    padding: 0 15px;
    width: 10%;
    height: 30px;
  }
}

.check_result {
  margin: 20px auto 0;
  width: 94%;
  padding-bottom: 10px;

  .progress {
    padding: 10px auto;

    .progress_info {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .el-progress {
      margin-bottom: 10px;
    }
  }

  .result_top {
    background-color: var(--main);
    padding: 6px 20px 10px;
    border-radius: 10px;

    :deep(.el-form) {
      margin-top: 6px;
    }

    .el-textarea {
      display: block;
      margin: 0 auto 10px;
    }

    h4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      height: 50px;

      div {
        height: 30px;
      }

      .el-button {
        height: 100%;
      }

      span {
        margin-right: 10px;
      }
    }
  }

  .result_bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      padding: 0 10px 12px;
      width: 30%;
      background-color: var(--main);
      border-radius: 10px;

      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding-left: 10px;

        span {
          margin-right: 10px;
        }
      }

      .el-textarea {
        display: block;
        margin: 0 auto 10px;
        border-radius: 16px;

        :deep(.el-textarea__inner) {
          border-radius: 16px;
        }
      }
    }
  }
}
</style>
