import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home/index.vue'
import Layout from '@/views/Layout/index.vue'
import { useUserStore } from '@/stores'
import NProgress from 'nprogress'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: '/',
      component: Layout,
      redirect: '/home',
      children: [
        {
          path: 'home',
          name: 'home',
          component: Home
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('@/views/About/index.vue')
        },
        {
          path: 'order',
          name: 'order',
          component: () => import('@/views/Order/index.vue')
        },
        {
          path: 'admin-bin',
          name: 'admin-bin',
          component: () => import('@/views/AdminBin/index.vue')
        },
        {
          path: 'detail',
          name: 'detail',
          component: () => import('@/views/Detail/index.vue')
        },
        {
          path: 'admin-key',
          name: 'admin-key',
          component: () => import('@/views/AdminKey/index.vue')
        },
        {
          path: 'admin-order',
          name: 'admin-order',
          component: () => import('@/views/AdminOrder/index.vue')
        },
        {
          path: 'admin-setting',
          name: 'admin-setting',
          component: () => import('@/views/AdminSetting/index.vue')
        },
        {
          path: 'admin-message',
          name: 'admin-message',
          component: () => import('@/views/Adminmessage/index.vue')
        },
        {
          path: 'docs',
          name: 'docs',
          component: () => import('@/views/Docs/index.vue'),
          redirect: { name: 'check' },
          children: [
            {
              path: 'check',
              name: 'check',
              component: () => import('@/views/Docs/Check/index.vue')
            },
            {
              path: 'checkresult',
              name: 'checkresult',
              component: () => import('@/views/Docs/CheckResult/index.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login/index.vue')
    },
    {
      path: '/404',
      component: () => import('@/views/error/404.vue'),
      meta: { hidden: true }
    }
  ]
})

 router.beforeEach((to) => {
   const store = useUserStore()
   const whiteList = [
     '/home',
     '/404',
     '/about',
     '/login',
     '/docs',
     '/docs/check',
     '/docs/checkresult',
     "admin-message"
     
   ]
   if (!store.userId && !whiteList.includes(to.path)) {
     return '/login'
   }
   NProgress.start()
 })

router.afterEach((to) => {
  NProgress.done()
})
/**
 * 重置路由
 */
export function resetRouter() {
  router.replace({ path: '/login' })
}

export default router
