import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useUserStore = defineStore(
  'user',
  () => {
    const isSuperUser = ref('')

    const userId = ref('')

    const balance = ref()

    // 订单页死卡活卡
    const card_check_no = ref('')


    const setCardCheckNo = (u: any) => {
      card_check_no.value = u
    }




    const setUser = (u: any) => {
      isSuperUser.value = u.is_super
      userId.value = u.user_id
    }

    const setBalance = (u: any) => {
      // console.log(u)
      balance.value = u
      // console.log(balance.value)
    }

    const removeUser = () => {
      isSuperUser.value = ''
      userId.value = ''
      balance.value = ''
    }
    return { balance, isSuperUser, userId, setUser, removeUser, setBalance,setCardCheckNo ,card_check_no}
  },
  {
    persist: {
      storage: sessionStorage
    }
  }
)
